import React, {useEffect, useState} from 'react';

import {ArticleReactionsAuthModal} from '../article-reactions-auth-modal';

import {SHOW_ARTICLE_AUTH_MODAL_EVENT} from './constants';

const ArticleAuthModal = () => {
  const [isOpenAuthModal, setIsOpenAuthModal] = useState(false);
  const [modalInitiator, setModalInitiator] = useState('');

  const showArticleAuthModal = event => {
    setModalInitiator(event.detail.initiator);
    setIsOpenAuthModal(true);
  };

  useEffect(() => {
    document.addEventListener(SHOW_ARTICLE_AUTH_MODAL_EVENT, showArticleAuthModal);
    return () => {
      document.removeEventListener(SHOW_ARTICLE_AUTH_MODAL_EVENT, showArticleAuthModal);
    };
  }, []);

  return (isOpenAuthModal && modalInitiator) && (
    <ArticleReactionsAuthModal
      modalInitiator={modalInitiator}
      onClose={() => setIsOpenAuthModal(false)}
    />
  );
};

export {ArticleAuthModal};

const SHOW_ARTICLE_AUTH_MODAL_EVENT = 'SHOW_ARTICLE_AUTH_MODAL_EVENT';

const AUTH_MODAL_INITIATORS = {
  ARTICLE_REACTION: 'ARTICLE_REACTION',
  COMMENT_REACTION: 'COMMENT_REACTION'
};

function dispatchArticleAuthModal (initiator = AUTH_MODAL_INITIATORS.ARTICLE_REACTION) {
  document.dispatchEvent(new CustomEvent(SHOW_ARTICLE_AUTH_MODAL_EVENT, {
    detail: {initiator}
  }));
}

export {
  SHOW_ARTICLE_AUTH_MODAL_EVENT,
  AUTH_MODAL_INITIATORS,
  dispatchArticleAuthModal
};

import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {Button, BUTTON_VARIANTS, Typography} from '@nur-portal/ui-kit';

import {initAppleIdAuth} from '../../../apple-id-auth';
import {GOOGLE_AUTH_SOURCE_STORAGE_NAME, GOOGLE_AUTH_SOURCES} from '../../../constants/google-auth';
import {getImageUrl} from '../../../helpers/image';
import {handleLoginEvent} from '../../../modules/auth-buttons';
import {createLinkToLogin} from '../../../modules/auth-pages/constants';
import {AppleButton} from '../apple-button';
import {APPLE_BUTTON_SOURCES} from '../apple-button/constants';
import {AUTH_MODAL_INITIATORS} from '../article-auth-modal/constants';
import Modal from '../comments/modal/modal';
import {GoogleButton} from '../google-button';
import {GOOGLE_BUTTON_SOURCES} from '../google-button/constants';

import styles from './article-reactions-auth-modal.module.css';

const GOOGLE_BUTTON_MAX_WIDTH = 370;
const DEFAULT_PADDINGS = 70;

const AUTH_BUTTON_WIDTH = document.body.clientWidth >= GOOGLE_BUTTON_MAX_WIDTH + DEFAULT_PADDINGS
  ? GOOGLE_BUTTON_MAX_WIDTH
  : document.body.clientWidth - DEFAULT_PADDINGS;

const ArticleReactionsAuthModal = ({
  onClose,
  modalInitiator
}) => {
  const {t} = useTranslation();

  const onGoogleButtonClick = () => {
    localStorage.setItem(GOOGLE_AUTH_SOURCE_STORAGE_NAME,
      modalInitiator === AUTH_MODAL_INITIATORS.ARTICLE_REACTION
        ? GOOGLE_AUTH_SOURCES.ARTICLE_REACTIONS
        : GOOGLE_AUTH_SOURCES.COMMENT_REACTIONS
    );
  };

  const onDefaultButtonClick = () => {
    onClose();

    window.location.href = createLinkToLogin({
      redirectURL: location.pathname,
      phoneNumber: ' '
    });
  };

  const googleButtonSource = modalInitiator === AUTH_MODAL_INITIATORS.ARTICLE_REACTION
    ? GOOGLE_BUTTON_SOURCES.REACTIONS_MODAL_ARTICLE
    : GOOGLE_BUTTON_SOURCES.REACTIONS_MODAL_COMMENT;

  const appleButtonSource = modalInitiator === AUTH_MODAL_INITIATORS.ARTICLE_REACTION
    ? APPLE_BUTTON_SOURCES.REACTIONS_MODAL_ARTICLE
    : APPLE_BUTTON_SOURCES.REACTIONS_MODAL_COMMENT;

  useEffect(() => {
    initAppleIdAuth(null, appleButtonSource);
    handleLoginEvent(onClose);
  }, []);

  return (
    <Modal
      onClickOutside={onClose}
      isShow
      className={styles.modalWrapper}
    >
      <div className={styles.container}>
        <img
          src={getImageUrl('/icons/close-burger.svg')}
          className={styles.closeIcon}
          alt="close"
          onClick={onClose}
        />
        <img src={getImageUrl('/article/reactions-modal-image.svg')} alt="smile" className={styles.image} />
        <Typography variant="title-21" className={styles.text}>
          {t('articleReactions.modal.text')}
        </Typography>
        <GoogleButton
          source={googleButtonSource}
          buttonWidth={AUTH_BUTTON_WIDTH}
          onClick={onGoogleButtonClick}
        />
        <div className={styles.authButtonDivider}>
          <AppleButton
            source={appleButtonSource}
            width={AUTH_BUTTON_WIDTH}
          />
        </div>
        <div className={styles.divider}>
          <Typography variant="text-14" className={styles.dividerText}>
            {t('articleReactions.modal.delimiter')}
          </Typography>
        </div>
        <Button
          className={styles.signupButton}
          variant={BUTTON_VARIANTS.SECONDARY}
          onClick={onDefaultButtonClick}
        >
          {t('articleReactions.modal.login')}
        </Button>
      </div>
    </Modal>
  );
};

export {ArticleReactionsAuthModal};


import React, {useEffect, useMemo} from 'react';
import ReactDOM from 'react-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';

// Styles
import styles from './modal.module.css';

const Modal = ({isShow, children, className, onClickOutside}) => {
  const previousBodyOverflowValue = useMemo(() => document.body.style.overflow, []);

  const handleClickOutsideTheModal = event => {
    const isTargetTheModalWrapper = event.target.classList.contains(styles.modalWrapper);

    if (isTargetTheModalWrapper && typeof onClickOutside === 'function') {
      onClickOutside();
    }
  };

  useEffect(() => {
    document.body.style.overflow = isShow ? 'hidden' : previousBodyOverflowValue;

    return () => {
      document.body.style.overflow = previousBodyOverflowValue;
    };
  }, [isShow]);

  if (!isShow) {
    return null;
  }

  return ReactDOM.createPortal((
    <div onClick={handleClickOutsideTheModal} className={clsx(styles.modalWrapper, className)}>
      {children}
    </div>
  ), document.body);
};

Modal.propTypes = {
  isShow: PropTypes.bool.isRequired,
  onClickOutside: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  className: PropTypes.string
};

Modal.defaultProps = {
  className: ''
};

export default Modal;

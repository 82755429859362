// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".article-reactions-auth-modal-module__modalWrapper--1eYhu{display:flex;justify-content:center;align-items:center;box-sizing:border-box}@media (--madium){.article-reactions-auth-modal-module__modalWrapper--1eYhu{padding:0}}.article-reactions-auth-modal-module__container---LQws{background-color:#fff;width:calc(100% - 30px);max-width:630px;display:flex;flex-direction:column;position:relative;align-items:center;padding:65px 20px 60px;box-sizing:border-box;height:calc(100% - 30px);justify-content:center}@media screen and (min-width:756px){.article-reactions-auth-modal-module__container---LQws{height:auto}}.article-reactions-auth-modal-module__closeIcon--2h3Ll{position:absolute;top:20px;right:20px;width:30px;height:30px;cursor:pointer}.article-reactions-auth-modal-module__image--3zIcH{display:block;width:80px;height:80px;margin-bottom:15px}.article-reactions-auth-modal-module__divider--132kb{margin:25px auto;display:flex;width:100%;max-width:370px;justify-content:center;position:relative}.article-reactions-auth-modal-module__divider--132kb:before{content:\"\";position:absolute;left:0;right:0;top:9px;height:1px;background-color:#e2e2e2}.article-reactions-auth-modal-module__dividerText--3uZNg{display:block;padding:0 10px;background-color:#fff;color:#757575;z-index:1}.article-reactions-auth-modal-module__text--pcB5C{max-width:280px;margin-bottom:20px;color:#212121;text-align:center}.article-reactions-auth-modal-module__signupButton--2BDas{max-width:370px;width:100%}.article-reactions-auth-modal-module__authButtonDivider--1ZbBX{margin-top:15px}", ""]);
// Exports
exports.locals = {
	"modalWrapper": "article-reactions-auth-modal-module__modalWrapper--1eYhu",
	"container": "article-reactions-auth-modal-module__container---LQws",
	"closeIcon": "article-reactions-auth-modal-module__closeIcon--2h3Ll",
	"image": "article-reactions-auth-modal-module__image--3zIcH",
	"divider": "article-reactions-auth-modal-module__divider--132kb",
	"dividerText": "article-reactions-auth-modal-module__dividerText--3uZNg",
	"text": "article-reactions-auth-modal-module__text--pcB5C",
	"signupButton": "article-reactions-auth-modal-module__signupButton--2BDas",
	"authButtonDivider": "article-reactions-auth-modal-module__authButtonDivider--1ZbBX"
};
module.exports = exports;

import React from 'react';
import {render} from 'react-dom';

import {ArticleAuthModal} from './website-ui/components/article-auth-modal';

const rootElement = document.getElementById('article-auth-modal');

if (rootElement) {
  render(
    <ArticleAuthModal />,
    rootElement
  );
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".modal-module__modalWrapper--2MMVA{width:100%;height:100%;position:fixed;overflow:hidden;top:0;left:0;background-color:#000;background-color:rgba(0,0,0,.5);z-index:100}", ""]);
// Exports
exports.locals = {
	"modalWrapper": "modal-module__modalWrapper--2MMVA"
};
module.exports = exports;
